import React, { useState, useEffect } from 'react'
import './RegionPage.scss'
// import PictureSquare from '../../components/pictureSquare/PictureSquare';
import { Grid/*, useTheme */, useMediaQuery, Button } from '@mui/material'
import { DeleteOutline } from '@mui/icons-material/'
// import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { getSeniors, deleteSenior } from '../../api/senior'
import Loading from '../../components/loading/Loading'
import { useDispatch, useSelector } from 'react-redux'
// import DialogAddAlbum from '../../components/dialog/DialogAddAlbum/DialogAddAlbum'
import DialogAreYouSure from '../../components/dialog/DialogAreYouSure/DialogAreYouSure'
// import DialogAddMovie from '../../components/dialog/DialogAddMovie/DialogAddMovie'
import { setInfo } from '../../store/snackbar'
import { updateSeniors } from '../../store/region'
import DialogAddSenior from '../../components/dialog/DialogAddSenior/DialogAddSenior'
import UserCardRectangle from '../../components/userCardRectangle/UserCardRectangle'
import UserCardSquare from '../../components/userCardSquare/UserCardSquare'
import _ from 'lodash'

export default function RegionPage () {
  const matches = useMediaQuery('(max-width:780px)')
  const { t } = useTranslation()
  const seniors = useSelector(state => state.region.seniors)
  const [isLoading, setIsLoading] = useState(true)
  const [openAreYouSure, setOpenAreYouSure] = useState(false)
  const [openAddSenior, setOpenAddSenior] = useState(false)
  const [toDeleteSenior, setToDeleteSenior] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    let didCancel = false
    window.scrollTo(0, 0)
    async function retrieveSeniors () {
      try {
        if (seniors.length === 0) {
          const seniors = await getSeniors()
          if (seniors.length > 0) !didCancel && dispatch(updateSeniors(seniors))
        }
        setIsLoading(false)
      } catch (e) {
        dispatch(setInfo({ open: true, message: 'region.seniors.error.getDetail', severity: 'error' }))
        setIsLoading(true)
      }
    }

    Promise.all([retrieveSeniors()])

    return () => { didCancel = true }
  }, [seniors])

  const handleDeleteSenior = async () => {
    try {
      await deleteSenior(toDeleteSenior)
      setToDeleteSenior(null)
      const seniors = await getSeniors()
      dispatch(updateSeniors(seniors))
    } catch (e) {
      dispatch(setInfo({ open: true, message: 'region.seniors.error.delete', severity: 'error' }))
    }
  }

  const userComponent = (users) => {
    const cardUsers = users.map(el =>
      <Grid item sm={2} xs={12} key={`user_${el.id}`}>
        {matches
          ? <>
              <UserCardRectangle user={el} />
              <div className='buttonContainer'>
                <Button variant='contained' color='primary' onClick={() => { setToDeleteSenior(el.id); setOpenAreYouSure(true) }}><DeleteOutline className='deleteIcon' /></Button>
              </div>
            </>
          : <>
              <UserCardSquare user={el} />
              <div className='buttonContainer'>
                <Button variant='contained' color='primary' onClick={() => { setToDeleteSenior(el.id); setOpenAreYouSure(true) }}><DeleteOutline className='deleteIcon' /></Button>
              </div>
            </>}
      </Grid>)
    const chunkedArray = _.chunk([...cardUsers], 5)
    if (chunkedArray[chunkedArray.length - 1].length < 5) {
      chunkedArray[chunkedArray.length - 1] = _.concat(chunkedArray[chunkedArray.length - 1], Array(5 - chunkedArray[chunkedArray.length - 1].length).fill(<Grid item sm={2} xs={6} />))
    }
    return chunkedArray.map((subArr, i) => (
      <Grid container justifyContent='space-between' spacing={matches ? 1 : 2} style={{ marginBottom: '20px' }} key={`section_user_${i}`}>
        {
                    subArr.map((el, j) => <React.Fragment key={`user_card_${i}_${j}`}>{el}</React.Fragment>)
                }
      </Grid>)
    )
  }

  if (isLoading) {
    return (<Loading />)
  }

  return (
    <div className='regionPage'>
      {openAddSenior && <DialogAddSenior
        open={openAddSenior} setOpen={() => setOpenAddSenior(false)}
        title={t('region.seniors.add.button')}
                        />}
      {openAreYouSure && toDeleteSenior && <DialogAreYouSure
        open={openAreYouSure} setOpen={() => setOpenAreYouSure(false)}
        title={t('region.seniors.delete.title')} content={t('actions.areYouSure')} onClick={handleDeleteSenior}
                                           />}
      <Grid container alignItems='center'>
        <Grid item sm={6} xs={12} className='title' container alignItems='center' justifyContent='center' spacing={1}>
          <Grid item>
            <h2>
              {t('region.title')}
            </h2>
          </Grid>
        </Grid>

      </Grid>
      <h3>{t('region.seniors.title')}</h3>
      <Button variant='contained' color='primary' onClick={(e) => setOpenAddSenior(true)}>{t('region.seniors.add.button')}</Button>
      <br /><br />
      {userComponent(seniors)}
    </div>
  )
}
