export const de = {
  home: {
    visio: 'Videoanruf',
    messages: 'Benachrichtigungen',
    rdv: 'Kalender',
    medias: 'Medien',
    contacts: 'Kontakte',
    settings: 'Einstellungen'
  },
  medias: {
    album: {
      count: '{{count}} Element(e)',
      add: 'Hinzufügen',
      private: 'privat'
    },
    list: {
      seeMore: 'Mehr anzeigen',
      seeLess: 'Weniger anzeigen',
      photos: 'Fotoalben',
      musics: 'Musikalben',
      movies: 'Videotheken',
      audioseries: 'Audio-Inhalte'
    },
    title: 'Medien',
    addAudioserie: {
      label: {
        url: 'Internet-Link (URL) zum Inhalt einfügen'
      }
    },
    addAlbum: {
      photos: 'Fotoalbum hinzufügen',
      musics: 'Musikalbum hinzufügen',
      movies: 'Videothek hinzufügen',
      audioserie: 'Audio-Inhalte hinzufügen',
      label: {
        name: 'Name eingeben',
        private: 'Privat stellen?'
      },
      updatePhoto: 'Titelbild bearbeiten',
      addPhoto: 'Titelbild hinzufügen',
      deletePhoto: 'Titelbild entfernen'
    },
    editAlbum: {
      photos: 'Fotoalbum bearbeiten',
      musics: 'Musikalbum bearbeiten',
      movies: 'Videothek bearbeiten',
      audioseries: 'Audio-Inhalte bearbeiten'
    },
    deleteAlbum: {
      photos: 'Fotoalbum löschen',
      musics: 'Musikalbum löschen',
      movies: 'Videothek löschen',
      audioserie: 'Audio-Inhalte löschen'
    },
    error: {
      getPhotoAlbums: 'Beim Abrufen der Fotoalben ist ein Fehler aufgetreten',
      addPhotoAlbum: 'Beim Erstellen des Fotoalbums ist ein Fehler aufgetreten',
      getMusicLists: 'Beim Abrufen der Musikalben ist ein Fehler aufgetreten',
      addMusicList: 'Beim Erstellen des Musikalbums ist ein Fehler aufgetreten',
      getMovieLists: 'Beim Abrufen der Videotheken ist ein Fehler aufgetreten',
      addMovieList: 'Beim Erstellen der Videothek ist ein Fehler aufgetreten',
      getAudioseries: 'Beim Abrufen des Audio-Inhaltees ist ein Fehler aufgetreten',
      addAudioserie: 'Beim Erstellen des Audio-Inhaltees ist ein Fehler aufgetreten',
      delete_photos: 'Beim Löschen der Fotoalbums ist ein Fehler aufgetreten',
      delete_musics: 'Beim Löschen des Musikalbums ist ein Fehler aufgetreten',
      delete_movies: 'Beim Löschen der Videothek ist ein Fehler aufgetreten',
      delete_audioseries: 'Beim Löschesn des Audio-Inhaltes ist ein Fehler aufgetreten'
    }
  },
  photos: {
    title: 'Fotos',
    dragAndDrop: 'Nutzen Sie Drag & Drop, um Ihre Fotos dem Album hinzuzufügen',
    delete: 'Foto löschen',
    error: {
      get: 'Beim Abrufen der Fotos ist ein Fehler aufgetreten',
      getAlbum: 'Beim Abrufen des Fotoalbums ist ein Fehler aufgetreten',
      update: 'Beim Hinzufügen der Fotos ist ein Fehler aufgetreten',
      updateAlbum: 'Beim Aktualisieren des Fotoalbums ist ein Fehler aufgetreten',
      delete: 'Beim Löschen des Fotos ist ein Fehler aufgetreten'
    }
  },
  musics: {
    title: 'Musik',
    desc: 'Klicken Sie auf ein Musikstück, um es abzuspielen',
    add: {
      title: 'Musikstück hinzufügen',
      name: 'Name des Musikstücks',
      artist: 'Name des Künstlers',
      url: 'Link zum Musikstück'
    },
    delete: {
      title: 'Supprimer la musique'
    },
    emptyList: 'Das Musikalbum ist leer, ',
    count: '{{count}} Musikstück(e)',
    error: {
      get: 'Beim Abrufen der Musikstücke ist ein Fehler aufgetreten',
      getList: 'Beim Abrufen des Musikalbums ist ein Fehler aufgetreten',
      add: 'Beim Hinzufügen des Musikstücks ist ein Fehler aufgetreten',
      updateList: 'Beim Aktualisieren des Musikalbums ist ein Fehler aufgetreten',
      delete: 'Beim Löschen des Musikstücks ist ein Fehler aufgetreten'
    }
  },
  movies: {
    title: 'Filme',
    desc: 'Klicken Sie auf einen Film, um ihn anzusehen',
    add: {
      title: 'Film hinzufügen',
      name: 'Name des Films',
      url: 'Link zum Film'
    },
    delete: {
      title: 'Film löschen'
    },
    emptyList: 'Die Videothek ist leer, ',
    count: '{{count}} Film(e)',
    error: {
      get: 'Beim Abrufen der Filme ist ein Fehler aufgetreten',
      getList: 'Beim Abrufen der Videothek ist ein Fehler aufgetreten',
      add: 'Beim Hinzufügen des Films ist ein Fehler aufgetreten',
      updateList: 'Beim Aktualisieren der Videothek ist ein Fehler aufgetreten',
      delete: 'Beim Löschen des Films ist ein Fehler aufgetreten'
    }
  },
  episodes: {
    title: 'Episoden',
    desc: 'Klicken Sie auf eine Episode, um sie anzuhören',
    emptyList: 'Die Audio-Inhalte sind leer, ',
    count: '{{count}} Episode(n)',
    error: {
      get: 'Beim Abrufen der Episoden ist ein Fehler aufgetreten',
      getList: 'Beim Abrufen der Audio-Inhalte ist ein Fehler aufgetreten'
    },
    open_website: 'Auf der ARD Audiothek Website ansehen'
  },
  events: {
    title: 'Kalender',
    other: '{{count}} andere(s)',
    new: {
      title: 'Neuen Termin erstellen'
    },
    edit: {
      title: 'Termin bearbeiten'
    },
    delete: {
      title: 'Termin löschen'
    },
    all: {
      title: 'Terminliste'
    },
    fields: {
      name: 'Name des Termins',
      date: 'Datum'
    },
    add: 'Erstellen',
    error: {
      get: 'Beim Abrufen der Termine ist ein Fehler aufgetreten',
      create: 'Beim Hinzufügen des Termins ist ein Fehler aufgetreten',
      update: 'Beim Aktualisieren des Termins ist ein Fehler aufgetreten',
      delete: 'Beim Löschen des Termins ist ein Fehler aufgetreten'
    }
  },
  users: {
    title: 'Kontakte',
    new: {
      title: 'Benutzer einladen',
      invite: 'Einladen'
    },
    me: 'Ich',
    error: {
      get: 'Die Kontakte sind derzeit nicht verfügbar',
      create: 'Beim Einladen des Kontakts ist ein Fehler aufgetreten'
    }
  },
  messages: {
    title: 'Benachrichtigungen',
    add: {
      title: 'Neue Nachricht',
      subject: 'Thema',
      body: 'Hauptteil der Nachricht'
    },
    edit: {
      title: 'Nachricht bearbeiten',
      updatedAt: 'Gesendet am {{date}} um {{time}} Uhr',
      readAt: 'Gelesen am {{date}} um {{time}} Uhr',
      notRead: 'Ungelesen'
    },
    delete: {
      title: 'Nachricht löschen'
    },
    empty: 'Es wurde noch keine Nachricht an {{firstname}} gesendet, klicken Sie auf <0>"Neue Nachricht"</0>!',
    emptyDefault: 'Ihr Angehöriger',
    error: {
      get: 'Beim Abrufen der Nachrichten ist ein Fehler aufgetreten',
      send: 'Beim Senden der Nachricht ist ein Fehler aufgetreten',
      update: 'Beim Aktualisieren der Nachricht ist ein Fehler aufgetreten',
      delete: 'Beim Löschen der Nachricht ist ein Fehler aufgetreten'
    }
  },
  visio: {
    title: 'Videoanruf',
    copy: 'Einladungslink kopieren',
    boxConnected: 'Die Box ist angeschlossen.',
    boxDisconnected: 'Die Box ist getrennt.',
    call: 'Rufen',
    error: {
      get: 'Videoanrufe sind derzeit nicht verfügbar'
    },
    calling_you: '{{firstname}} ruft Sie an',
    accept: 'Annehmen',
    refuse: 'Abweisen',
    missed_call: 'Verpasster Anruf von {{firstname}}',
    seen: 'Notiert'
  },
  profile: {
    title: 'Mein Profil',
    save: 'Speichern',
    password: 'Mein Passwort',
    error: {
      update: 'Beim Aktualisieren des Profils ist ein Fehler aufgetreten',
      updatePwd: 'Beim Aktualisieren des Passworts ist ein Fehler aufgetreten'
    }
  },
  invitation: {
    title: 'Mein Konto erstellen',
    save: 'Erstellen',
    error: {
      get: 'Beim Abrufen des Kontos ist ein Fehler aufgetreten',
      update: 'Beim Aktualisieren des Kontos ist ein Fehler aufgetreten'
    }
  },
  login: {
    title: 'Herzlich Willkommen beim Kissen-Viktor',
    text: 'Bitte melden Sie sich an.',
    button: 'Anmelden',
    forgotPwd: 'Passwort vergessen',
    error: {
      login: 'Beim Anmelden ist ein Fehler aufgetreten'
    }
  },
  forgotPwd: {
    title: 'Passwort vergessen',
    button: 'Zurücksetzen',
    text: 'Sie erhalten in wenigen Augenblicken einen Link zum Zurücksetzen, wenn ihre E-Mail Adresse korrekt registriert wurde.',
    error: {
      send: 'Bei der Aufforderung zum Zurücksetzen ist ein Fehler aufgetreten'
    }
  },
  resetPwd: {
    title: 'Passwort zurücksetzen',
    button: 'Zurücksetzen',
    error: {
      get: 'Beim Abrufen des Gastkontos ist ein Fehler aufgetreten',
      update: 'Beim Aktualisieren des Passworts ist ein Fehler aufgetreten'
    }
  },
  settings: {
    title: 'Einstellungen',
    notifications: {
      title: 'Benachrichtigungen',
      text: 'Die Box, die bei Ihrem Angehörigen installiert ist, erhält gelegentlich Benachrichtigungen. Das können Benachrichtigungen für Videoanrufe, Terminerinnerungen, wichtige Warnmeldungen oder eine Bestätigung über den Start des SOS-Systems sein. Sie können ein Zeitfenster einstellen, in dem Ihr Angehöriger diese Benachrichtigungen erhalten kann, damit Sie ihn nicht zu unpassenden Zeiten stören.',
      time: 'Aktives Zeitfenster (Ihr Angehöriger erhält Benachrichtigungen)',
      mute: '"Nicht stören" Zeitfenster (Ihr Angehöriger erhält keine Benachrichtigungen)',
      error: {
        get: 'Beim Abrufen der Nutzerinformationen ist ein Fehler aufgetreten',
        update: 'Beim Aktualisieren der Benachrichtigungen ist ein Fehler aufgetreten'
      }
    },
    sosContact: {
      title: 'SOS Konfiguration',
      text: 'Mit dem Kissen-Viktor können Sie ihre Angehörigen kontaktieren, indem Sie ihnen eine E-Mail senden.',
      add: {
        title: 'Neuen SOS-Kontakt hinzufügen'
      },
      edit: {
        title: 'SOS-Kontakt bearbeiten'
      },
      delete: {
        title: 'SOS-Kontakt löschen'
      },
      error: {
        get: 'Beim Abrufen der SOS-Kontakte ist ein Fehler aufgetreten',
        add: 'Beim Hinzufügen des SOS-Kontakts ist ein Fehler aufgetreten',
        update: 'Beim Aktualisieren des SOS-Kontakts ist ein Fehler aufgetreten',
        delete: 'Beim Löschen des SOS-Kontakts ist ein Fehler aufgetreten'
      }
    }
  },
  form: {
    phone: {
      label: 'Mobilnummer',
      helper: 'Die Telefonnummer muss die Landesvorwahl enthalten (+491022030405)'
    },
    mail: {
      label: 'E-Mail',
      helper: 'Die E-Mail-Adresse ist erforderlich und muss gültig sein (beispiel@beispiel.com)'
    },
    password: {
      label: 'Passwort',
      helper: 'Das Passwort ist erforderlich und muss 8 Zeichen enthalten. Unter anderem: 1 Zahl, 1 Großbuchstabe, 1 Kleinbuchstabe'
    },
    newPassword: {
      label: 'Neues Passwort',
      helper: 'Das neue Passwort ist erforderlich und muss 8 Zeichen enthalten. Unter anderem: 1 Zahl, 1 Großbuchstabe, 1 Kleinbuchstabe'
    },
    confirmedNewPassword: {
      label: 'Neues Passwort bestätigen',
      helper: 'Die Passwörter stimmen nicht überein'
    },
    firstname: {
      label: 'Vorname',
      helper: 'Der Vorname ist erforderlich'
    },
    lastname: {
      label: 'Nachname',
      helper: 'Der Nachname ist erforderlich'
    },
    isContactMeRecipient: {
      label: 'Empfänger der Nachrichtenfunktion "Kontakt Anfrage"'
    },
    startAt: {
      label: 'Beginn'
    },
    endAt: {
      label: 'Ende'
    }
  },
  actions: {
    create: 'Erstellen',
    edit: 'Bearbeiten',
    yes: 'Ja',
    no: 'Nein',
    areYouSure: 'Sind Sie sicher?',
    goBack: 'Zurück',
    goBackMedias: 'Zurück zu den Medien'
  },
  navbar: {
    login: 'Anmelden',
    logout: 'Abmelden',
    profile: 'Mein Profil',
    userMenu: {
      tooltip: 'Mein Konto'
    }
  },
  footer: {
    copyright: 'Hergestellt mit <0/> durch ABCo2'
  },
  error: {
    default: 'Ein Fehler ist aufgetreten'
  }
}
